import { Box, Button, Flex, Spacer, Text } from '@chakra-ui/react';
import { useTranslations } from 'next-intl';
import { useAppDispatch } from '../../hooks/store';

interface Props {
  firstName: string;
  lastName: string;
  email: string;
  key: number;
}

export default function EventAttendeeCard(props: Props) {
  const { firstName, lastName, email } = props;
  const t = useTranslations('Global');

  const dispatch: any = useAppDispatch();

  function onClickAddClass() {
    // dispatch(openCreateEventModal());
  }

  return (
    <>
      <Flex
        p="1rem"
        bgColor="white"
        justifyContent="center"
        borderRadius="0.75rem"
        direction="row"
        data-test-id="event-attendee-card"
        w="full"
      >
        <Box>
          <Text
            fontWeight={700}
            fontSize="1rem"
            textTransform="uppercase"
            mb={0}
          >{`${firstName} ${lastName}`}</Text>
          <Text fontSize="1rem">{email}</Text>
        </Box>
        <Spacer />
        <Button colorScheme="gray" size="xs">
          {t('eventAttendeeCard.removeButton')}
        </Button>
      </Flex>
    </>
  );
}
