import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EVENT_STATUS } from '../constants/enums';
import { ApiErrorResponse } from '../types/ApiErrors';
import { EventResponse } from '../types/events';
import { api } from './api';

// Routines slice stores the last retrieved routines data
// Populated as required/can be partially populated, e.g. if current set is required on first page but archive isn't
export const initialState: EventResponse[] = [];

const slice = createSlice({
  name: 'events',
  initialState: initialState,
  reducers: {
    clearEventsSlice(state) {
      return initialState;
    },

    setEventCancelled(state, action: PayloadAction<{ eventId: string }>) {
      const eventIndex = state.findIndex((event) => event.id === action.payload.eventId);
      state[eventIndex].status = EVENT_STATUS.CANCELLED;
      return state;
    },
  },

  extraReducers: (builder) => {
    builder.addMatcher(api.endpoints.getEvents.matchFulfilled, (state, { payload }) => {
      if (payload instanceof Array) {
        state = payload;
      }
      return state;
    });
    builder.addMatcher(api.endpoints.createEvent.matchFulfilled, (state, { payload }) => {
      if (!(payload instanceof ApiErrorResponse)) {
        state.push(payload);
        state.sort(function compare(eventA: EventResponse, eventB: EventResponse) {
          var dateA: number = new Date(eventA.dateTime).getTime();
          var dateB: number = new Date(eventB.dateTime).getTime();
          return dateA - dateB;
        });
      }
    });
  },
});

const { actions, reducer } = slice;
export const { clearEventsSlice, setEventCancelled } = actions;
export default reducer;
