import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_URL } from '../constants/variables';
import { ApiErrorResponse, MailchimpError } from '../types/ApiErrors';
import { Choreographer } from '../types/choreographers';
import { BossClass, getClassesDto } from '../types/classes';
import { EventCancelDto, EventCreateDto, EventResponse } from '../types/events';
import { NewsletterSubscribeRequestDto } from '../types/marketing/newsletterSubscribeRequest';
import { SubscriptionCheckoutRequestDto, SubscriptionCheckoutResponseDto } from '../types/payments';
import { getRoutineByDateDto, IrlRoutine, IrlRoutineCurrentSet, Routine } from '../types/routines';
import { User, UserCreateDto } from '../types/users';
import { Video } from '../types/videos';
import { RootState } from './store';

function getBaseQuery() {
  return fetchBaseQuery({
    baseUrl: API_URL,
    prepareHeaders: (headers, { getState }) => {
      const user = (getState() as RootState).user;
      const token = user.token;

      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  });
}

export const api = createApi({
  baseQuery: getBaseQuery(),
  endpoints: (builder) => ({
    createUser: builder.mutation<User | ApiErrorResponse, UserCreateDto>({
      query(body) {
        return {
          url: 'users',
          method: 'POST',
          body,
        };
      },
    }),
    getUser: builder.query<User | ApiErrorResponse, void>({
      query: () => 'users/me',
    }),
    updateUser: builder.mutation<User, Partial<User>>({
      query: ({ ...patch }) => ({
        url: `users/me`,
        method: 'PATCH',
        body: patch,
      }),
    }),
    subscribeToNewsletter: builder.mutation<
      {} | MailchimpError | ApiErrorResponse,
      NewsletterSubscribeRequestDto
    >({
      query(body) {
        return {
          url: 'marketing/newsletters/subscribe',
          method: 'POST',
          body,
        };
      },
    }),
    createSubscriptionCheckout: builder.mutation<
      SubscriptionCheckoutResponseDto | ApiErrorResponse,
      SubscriptionCheckoutRequestDto
    >({
      query(body) {
        return {
          url: 'payments/subscription-checkout',
          method: 'POST',
          body,
        };
      },
    }),
    getChoreographers: builder.query<Choreographer[] | ApiErrorResponse, void>({
      query: () => 'choreographers',
    }),
    getClasses: builder.query<BossClass[] | ApiErrorResponse, getClassesDto>({
      query: ({ lat, lng, radius }) => `classes?lat=${lat}&lng=${lng}&radius=${radius}`,
    }),
    getRoutinesCurrentSet: builder.query<IrlRoutineCurrentSet | ApiErrorResponse, void>({
      query: () => `routines/irl/current-set`,
    }),
    getRoutinesExclusive: builder.query<Routine[] | ApiErrorResponse, void>({
      query: () => `routines/exclusive`,
    }),
    getRoutinesArchive: builder.query<Routine[] | ApiErrorResponse, void>({
      query: () => `routines/irl/archive`,
    }),
    getRoutinesByDate: builder.query<IrlRoutine[] | ApiErrorResponse, getRoutineByDateDto>({
      query: ({ date }) => `routines/irl?date=${date}`,
    }),
    createEvent: builder.mutation<EventResponse | ApiErrorResponse, EventCreateDto>({
      query(body) {
        return {
          url: 'users/me/events',
          method: 'POST',
          body,
        };
      },
    }),
    getEvents: builder.query<EventResponse[] | ApiErrorResponse, void>({
      query: () => `users/me/events`,
    }),
    cancelEvent: builder.mutation<EventResponse | ApiErrorResponse, EventCancelDto>({
      query({ eventId }) {
        return {
          url: `users/me/events/${eventId}/cancel`,
          method: 'POST',
        };
      },
    }),
    getYogaVideos: builder.query<Video[] | ApiErrorResponse, void>({
      query: () => `videos?category=yoga`,
    }),
    getQAVideos: builder.query<Video[] | ApiErrorResponse, void>({
      query: () => `videos?category=qa`,
    }),
    getLifeSkillsVideos: builder.query<Video[] | ApiErrorResponse, void>({
      query: () => `videos?category=life_skills`,
    }),
    getStoriesVideos: builder.query<Video[] | ApiErrorResponse, void>({
      query: () => `videos?category=stories`,
    }),
    getMindsetVideos: builder.query<Video[] | ApiErrorResponse, void>({
      query: () => `videos?category=mindset`,
    }),
  }),
});

export const {
  useCreateUserMutation,
  useCreateSubscriptionCheckoutMutation,
  useGetChoreographersQuery,
  useGetUserQuery,
  useUpdateUserMutation,
  useGetClassesQuery,
  useGetRoutinesArchiveQuery,
  useGetRoutinesCurrentSetQuery,
  useGetRoutinesExclusiveQuery,
  useGetRoutinesByDateQuery,
  useCreateEventMutation,
  useGetEventsQuery,
  useCancelEventMutation,
  useSubscribeToNewsletterMutation,
  useGetQAVideosQuery,
  useGetLifeSkillsVideosQuery,
  useGetStoriesVideosQuery,
  useGetYogaVideosQuery,
  useGetMindsetVideosQuery,
} = api;
