import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EVENT_STATUS, EVENT_TYPE, LICENSE_TYPE } from '../constants/enums';
import { EventResponse, EventRoutineData } from '../types/events';
import { Place } from '../types/places';
import { BossUserInfo } from '../types/users';

export interface EventSlice {
  id: string | null;
  boss: BossUserInfo | null;
  dateTime: string | null;
  duration: number | null;
  routine: EventRoutineData | null;
  type: EVENT_TYPE | null;
  licenseType: LICENSE_TYPE | null;
  location: Place | null;
  ticketPrice: number | null;
  capacity: number | null;
  messageToQueens?: string | undefined | null;
  status: EVENT_STATUS | null;
}

export const initialState: EventSlice = {
  id: null,
  boss: null,
  dateTime: null,
  duration: null,
  routine: null,
  type: null,
  licenseType: null,
  location: null,
  ticketPrice: null,
  capacity: null,
  messageToQueens: null,
  status: null,
};

const slice = createSlice({
  name: 'events',
  initialState: initialState,
  reducers: {
    clearEventSlice(state) {
      return initialState;
    },

    setEvent(state, action: PayloadAction<EventResponse>) {
      state = action.payload;
      return state;
    },
  },
});

const { actions, reducer } = slice;
export const { clearEventSlice, setEvent } = actions;
export default reducer;
