import moment from 'moment';
import { EventResponse } from '../types/events';
import { capitalizeFirstLetter } from './string';

export function getEventDetailsString(event: EventResponse) {
  const startDate = new Date(event.dateTime);

  const startTime = moment(startDate).format('h:mma');
  const endDateTime = moment(startDate).add(event.duration, 'm').toDate();
  const endTime = moment(endDateTime).format('h:mma');

  const hours = Math.floor(event.duration / 60);
  const mins = event.duration % 60;

  return `${capitalizeFirstLetter(event.type)} · ${startTime} - ${endTime} · ${
    hours === 1 ? `1 hour` : hours > 1 ? `${hours} hours` : ''
  } ${mins > 0 ? `${mins} mins` : ''} · £${event.ticketPrice}`;
}
