import { Box, Button, Divider, Flex, Spacer, Tab, Tabs, Tag, Text } from '@chakra-ui/react';
import moment from 'moment';
import { useTranslations } from 'next-intl';
import { useState } from 'react';
import { ACTION_TYPE, EVENT_STATUS } from '../../constants/enums';
import { useAppDispatch, useTypedSelector } from '../../hooks/store';
import { useCancelEventMutation } from '../../store/api';
import { clearEventSlice } from '../../store/eventSlice';
import { setEventCancelled } from '../../store/eventsSlice';
import { clearModalsSlice } from '../../store/modalsSlice';
import { EventResponse } from '../../types/events';
import { getEventDetailsString } from '../../utils/event';
import EventAttendeeCard from '../cards/EventAttendeeCard';
import ConfirmAction from './ConfirmAction';

export default function ViewEvent() {
  const { event } = useTypedSelector((state) => state);

  const [cancelClassClicked, setCancelClassClicked] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const [cancelEvent] = useCancelEventMutation();

  const dispatch: any = useAppDispatch();

  const t = useTranslations('Global.viewEvent');

  const attendees: any = [
    {
      firstName: 'Emma',
      lastName: 'Collins',
      email: 'leah.topchik@gmail.com',
    },
    {
      firstName: 'Sophia',
      lastName: 'Brooks',
      email: 'leah@que.world',
    },
    {
      firstName: 'Lily',
      lastName: 'Anderson',
      email: 'leah@que.agency',
    },
    {
      firstName: 'Chloe',
      lastName: 'Bennett',
      email: 'leah@lgbt.fit',
    },
  ];

  const startDate = new Date(event.dateTime || '');
  const formattedDate = moment(startDate).format('dddd Do MMMM');

  function onClickCancelClass() {
    setCancelClassClicked(true);
  }

  async function confirmCancelClass() {
    const response = await cancelEvent({ eventId: event.id || '' });

    if ('error' in response) {
      setError('Failed to cancel class. Call admin if the problem persists.');
      setCancelClassClicked(false);
      return <ViewEvent />;
    }

    dispatch(setEventCancelled({ eventId: event.id || '' }));
    dispatch(clearModalsSlice());
    dispatch(clearEventSlice());
  }

  return (
    <>
      {cancelClassClicked ? (
        <ConfirmAction actionName={ACTION_TYPE.CANCEL_EVENT} confirmAction={confirmCancelClass} />
      ) : (
        <>
          <Box w="100%" mt="3.5rem" pt="1rem" px="4rem" data-test-id="view-event-box">
            <Flex direction="row">
              <Flex direction="column">
                <Tag
                  size="sm"
                  bgColor="pink.500"
                  textColor="white"
                  w="fit-content"
                  mb="1.313rem"
                  textTransform="capitalize"
                  data-test-id="routine-details-tag"
                >
                  {`${event.licenseType}: ${event.routine?.artist} - ${event.routine?.songName}`}
                </Tag>
                <Text
                  mb={0}
                  fontSize="1.688rem"
                  textTransform="uppercase"
                  fontWeight={700}
                  data-test-id="event-date"
                >
                  {`${formattedDate}`}
                </Text>
                <Text data-test-id="event-details" mb={0}>
                  {getEventDetailsString(event as EventResponse)}
                </Text>
                <Text data-test-id="location-name" mb={0}>
                  {event.location?.name}
                </Text>
                <Text data-test-id="location-address">{event.location?.formatted_address}</Text>
              </Flex>
              <Spacer />
              <Flex direction="column" gap="1rem" flexWrap="wrap" alignItems="center">
                <Flex
                  borderRadius="100%"
                  bgColor="pink.500"
                  w="104px"
                  h="104px"
                  justifyContent="center"
                >
                  <Text
                    textColor="white"
                    fontSize="1.875rem"
                    py="2rem"
                    px="1.5rem"
                    data-test-id="tickets-sold-capacity"
                  >{`${attendees.length}/${event.capacity}`}</Text>
                </Flex>
                <Text data-test-id="tickets-capacity-explanation" fontSize="0.75rem">
                  {t('ticketsSoldLabel')}
                </Text>
              </Flex>
            </Flex>
            <Divider mb={0} color="gray.300" borderBottomWidth="0.063rem" opacity={1} />
            <Flex direction="row" gap="0.75rem" alignItems="center" mt="0.125">
              <Tabs>
                <Tab px={0} color="pink.500">
                  {t('attendeesTitle')}
                </Tab>
              </Tabs>
              <Spacer />
              <Text fontSize="0.875rem" mb={0}>
                {`${t('editText')}`}
              </Text>
              <Button
                variant="outline"
                colorScheme="black"
                size="xs"
                onClick={onClickCancelClass}
                disabled={event.status === EVENT_STATUS.CANCELLED}
                data-test-id="cancel-button"
              >
                {t('cancelButton')}
              </Button>
            </Flex>
            {error && (
              <Text
                textAlign="end"
                textColor="pink.500"
                fontWeight="pink.500"
                data-test-id="error-message"
              >
                {error}
              </Text>
            )}
          </Box>
          <Box bgColor="gray.50" w="100%" h="fit-content" maxH="50vh" overflow="auto">
            <Flex
              mb="3.5rem"
              pt="1rem"
              px="4rem"
              gap="1rem"
              direction="column"
              data-test-id="attendees-list"
            >
              {attendees.map((attendee: any, index: number) => {
                return (
                  <EventAttendeeCard
                    firstName={attendee.firstName}
                    lastName={attendee.lastName}
                    email={attendee.email}
                    key={index}
                  />
                );
              })}
            </Flex>
          </Box>
        </>
      )}
    </>
  );
}
